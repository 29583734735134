<button
  mat-icon-button
  [matMenuTriggerFor]="tableSettings"
  data-cy="table-settings-button"
>
  <mat-icon>settings</mat-icon>
</button>
<mat-menu #tableSettings="matMenu" (click)="$event.stopImmediatePropagation()">
  <div
    fxLayout="column"
    [fxLayoutGap]="0.5 | dsSpacing"
    [style.min-width]="10 | dsSpacing"
    class="full-height"
  >
    <h4 fxFlex="0 0 auto">{{ 'general.table_settings' | translate }}</h4>
    <div
      fxFlex="0 1 auto"
      class="settings-container full-width no-min-height scroll-container-vertical"
      cdkDropList
      (cdkDropListDropped)="
        moveItemInArray(
          settings,
          $event.previousIndex + startIndex,
          $event.currentIndex + startIndex
        )
      "
    >
      <div *ngFor="let settings of settings">
        <div
          class="setting"
          mat-menu-item
          *ngIf="
            settings.disabledPosition === disabledPositionType.Start &&
            settings.name !== 'actions'
          "
          fxLayoutAlign="space-between center"
          (click)="$event.stopImmediatePropagation()"
        >
          <mat-checkbox
            [(ngModel)]="settings.selected"
            [disabled]="true"
            data-cy="table-settings-disabled-items-checkbox"
            >{{ _intl.getColumnName(settings.name) }}</mat-checkbox
          >
          <mat-icon>lock</mat-icon>
        </div>

        <div
          class="setting"
          mat-menu-item
          cdkDrag
          cdkDragBoundary=".settings-container"
          *ngIf="
            settings.disabledPosition === undefined ||
            settings.disabledPosition === disabledPositionType.Keep
          "
          fxLayoutAlign="space-between center"
          (click)="$event.stopImmediatePropagation()"
          cdkDragHandle
        >
          <mat-checkbox
            [(ngModel)]="settings.selected"
            [disabled]="!!settings.disabledPosition"
            (click)="$event.stopImmediatePropagation()"
            data-cy="table-settings-items-checkbox"
          >
            {{ _intl.getColumnName(settings.name) }}
          </mat-checkbox>
        </div>
      </div>
    </div>
    <button
      fxFlex="0 0 auto"
      class="save-button"
      mat-raised-button
      color="primary"
      (click)="onSave()"
      data-cy="table-settings-save-button"
    >
      {{ 'general.save' | translate }}
    </button>
  </div>
</mat-menu>
