import { moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { DisabledPositionType, TableSettings } from './models';
import { TableSettingsService } from './service/table-settings.service';
import { TableSettingsIntl } from './table-settings-intl';
@Component({
  selector: 'ds-table-settings',
  templateUrl: './table-settings.component.html',
  styleUrls: ['./table-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSettingsComponent {
  @Input()
  set defaultSettings(settings: TableSettings[]) {
    this.settings = cloneDeep(settings);
    this.startIndex = settings.filter(
      (x) =>
        x.disabledPosition === DisabledPositionType.Start &&
        x.name !== 'actions',
    ).length;
  }
  @Input()
  settingsKey: string;

  @Output()
  userSettings = new EventEmitter<Array<TableSettings>>();
  disabledPositionType = DisabledPositionType;
  settings: TableSettings[];
  startIndex: number;

  moveItemInArray = moveItemInArray;

  constructor(
    private tableSettingsService: TableSettingsService,
    public _intl: TableSettingsIntl,
  ) {}

  onSave(): void {
    this.tableSettingsService
      .set(this.settingsKey, this.settings)
      .subscribe(() => this.userSettings.emit(this.settings));
  }
}
