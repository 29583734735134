import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

export interface ContractTermsData {
  contract_type: string;
  contract_area: string;
}

@Component({
  standalone: true,
  selector: 'paldesk-contract-terms',
  templateUrl: './contract-terms.component.html',
  styleUrls: ['./contract-terms.component.scss'],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
  ],
})
export class ContractTermsComponent {
  public isGermanContract: boolean | undefined;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ContractTermsData) {
    this.detectGermanContract(data);
  }

  detectGermanContract(data: ContractTermsData): void {
    this.isGermanContract =
      data.contract_area === 'ZMWV' &&
      ['01', '02', '03'].includes(data.contract_type);
  }
}
