import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FeatureflagHandlerDirective } from './featureflag-handler.directive';
import { FeatureflagHandlerService } from './featureflag-handler.service';

@NgModule({
  imports: [CommonModule],
  providers: [FeatureflagHandlerService],
  declarations: [FeatureflagHandlerDirective],
  exports: [FeatureflagHandlerDirective],
})
export class SharedLibFeaturesFeatureflagHandlerModule {}
