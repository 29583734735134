import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { AppConfigurationClient } from '@azure/app-configuration';
import { catchError, from, map, Observable, of } from 'rxjs';

export const FEATUREFLAG_ACCESS_KEY = new InjectionToken<string>(
  'featureflagKey',
);
export const FEATUREFLAG_ENVIRONMENT = new InjectionToken<string>(
  'featureflagEnvironment',
);

@Injectable({
  providedIn: 'root',
})
export class FeatureflagHandlerService {
  protected accessKey = 'will be replaced';
  protected environment = 'will be replaced';
  constructor(
    @Optional() @Inject(FEATUREFLAG_ACCESS_KEY) accessKey: string,
    @Optional() @Inject(FEATUREFLAG_ENVIRONMENT) environment: string,
  ) {
    if (accessKey) {
      this.accessKey = accessKey;
    }
    if (environment) {
      this.environment = environment;
    }
  }
  GetRestriction(featureflag: string): Observable<boolean> {
    const client = new AppConfigurationClient(this.accessKey);
    return from(
      client.getConfigurationSetting(
        {
          key: '.appconfig.featureflag/' + featureflag,
          label: this.environment,
        },
        { onlyIfChanged: true },
      ),
    ).pipe(
      map((val) => (val.value ? JSON.parse(val.value).enabled : false)),
      catchError(() => of(false)),
    );
  }
}
