import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSortModule } from '@angular/material/sort';
import { DsFileDropModule } from '@design-system/cdk/file-drop';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsPlaceholderModule } from '@design-system/components/placeholder';
import { DsTableModule } from '@design-system/components/table';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { TranslateModule } from '@ngx-translate/core';
import { FileSizePipeModule } from '@utils/file-size-pipe';
import { IsImagePipeModule } from '@utils/is-image-pipe';
import { AttachmentsComponent } from './attachments/attachments.component';

@NgModule({
  imports: [
    CommonModule,
    DsTableModule,
    DsPlaceholderModule,
    DsTextIndicatorModule,
    DsSpacingModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    MatTableModule,
    MatTooltipModule,
    MatSortModule,
    MatProgressBarModule,
    TranslateModule,
    FlexLayoutModule,
    FileSizePipeModule,
    DsFileDropModule,
    IsImagePipeModule,
  ],
  declarations: [AttachmentsComponent],
  exports: [AttachmentsComponent],
})
export class DsComponentsAttachmentsModule {}
